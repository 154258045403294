import React from 'react';
import { useEffect, useState, useRef } from 'react';


const Start = () => {
    return (
        <div></div>
    );
};


export default Start;
